const zmoment = require('moment');
import Compressor from 'compressorjs';
import Litepicker from 'litepicker';

require('datatables.net-bs5');
const {v4: uuid} = require("uuid");

const ZURL = {
    getVars: () => {
        let vars = {};
        if (window.location.href.indexOf('?') > -1) {
            let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (let i = 0; i < hashes.length; i++) {
                let hash = hashes[i].split("#")[0];
                hash = hash.split('=');
                vars[hash[0]] = decodeURI(hash[1]);
            }
        }
        return vars;
    },
    setParams: (newParams) => {
        let vars = ZURL.getVars();
        for (let key in newParams)
            vars[key] = newParams[key];

        let parts = window.location.href.split('?');
        let baseURL = parts[0];
        let hash = '';
        let baseURLParts = baseURL.split("#");
        if (baseURLParts.length === 2) {
            hash = '#' + baseURLParts[1];
            baseURL = baseURLParts[0];
        }
        if (parts.length === 2) {
            let hashParts = parts[1].split('#');
            if (hashParts.length === 2)
                hash = '#' + hashParts[1];
        }

        if (Object.keys(vars).length)
            window.location.href = baseURL + '?' + $.param(vars) + hash;
        else
            window.location.href = baseURL + hash;
    },
    removeParams: (oldParams) => {
        let vars = ZURL.getVars();
        for (let key in oldParams)
            delete vars[key];


        let parts = window.location.href.split('?');
        let baseURL = parts[0];

        if (Object.keys(vars).length)
            window.location.href = baseURL + '?' + $.param(vars);
        else
            window.location.href = baseURL;
    },
    clearPramsNoReload: () => {
        const url = new URL(window.location.href);
        url.search = '';
        history.pushState({}, '', url.href);
    }
};
global.ZURL = ZURL;

const ZToasts = {
    toasts: [],
    init: () => {
        ZToasts.showOneToast();
    },
    showOneToast: () => {
        let errors = $('.toasts-error');
        if (errors.length) {
            ZToasts.error($(errors[0]).text());
            $(errors[0]).remove();
        }
        let notifications = $('.toasts-notification');
        if (notifications.length) {
            ZToasts.notification($(notifications[0]).text());
            $(notifications[0]).remove();
        }
    },
    makeNew: (parent) => {
        let a = document.createElement("div");
        a.innerHTML = `
        <div class="toast-body">Undefined.</div>`;
        a.classList.add("toast", "fade");
        let parentDiv = $(parent);
        parentDiv.append(a);
        let index = ZToasts.toasts.push(a);

        return index - 1;
    },
    error: (message) => {
        let a = ZToasts.makeNew('body');

        let options = {
            color: "danger",
            stacking: true,
            hidden: false,
            autohide: true,
            width: "350px",
            position: "top-right",
            delay: 5000,
        };
        $(ZToasts.toasts[a]).find(".toast-body").text(message);
        $(ZToasts.toasts[a]).click((e) => {
            $('.toast').remove();
        })
        let alertInstance = new mdb.Toast(ZToasts.toasts[a], options);

        alertInstance.show();
        $(ZToasts.toasts[a]).on("hidden.mdb.toast", () => {
            $(this).remove();
        });
    },
    notification: (message) => {
        let a = ZToasts.makeNew('body');

        let options = {
            color: "success",
            stacking: true,
            hidden: false,
            autohide: true,
            width: "350px",
            position: "top-right",
            delay: 5000,
        };
        $(ZToasts.toasts[a]).find(".toast-body").text(message);
        $(ZToasts.toasts[a]).click((e) => {
            $('.toast').remove();
        })
        let alertInstance = new mdb.Toast(ZToasts.toasts[a], options);

        alertInstance.show();
        $(ZToasts.toasts[a]).on("hidden.mdb.toast", () => {
            $(this).remove();
        });
    },
    tooltip: (message, parent) => {
        let a = ZToasts.makeNew(parent);

        let options = {
            color: "warning",
            stacking: true,
            hidden: false,
            autohide: true,
            width: "auto",
            position: "top-right",
            delay: 5000,
            container: parent
        };
        $(ZToasts.toasts[a]).find(".toast-body").html(message);
        $(ZToasts.toasts[a]).click((e) => {
            $('.toast').remove();
        })
        let alertInstance = new mdb.Toast(ZToasts.toasts[a], options);

        alertInstance.show();
        $(ZToasts.toasts[a]).on("hidden.mdb.toast", () => {
            $(this).remove();
        });
    }
};
global.ZToasts = ZToasts;

const ZFUNC = {
    washBadWords: (text, locale) => {
        return Wash.clean(locale, text);
    },
    formatNullText: (text, second) => {
        if (!second)
            second = "";
        return (text === null) ? second : text + second;
    },
    formatDate: (date, locale, offset) => {
        date = ZFUNC.parseDateWithOffset(date, locale, offset);
        if (locale === "de")
            return date.format("DD.MM.YYYY");
        return date.format("DD/MM/YYYY");
    },
    formatDateTime: (date, locale) => {
        if (locale === "de")
            return date.format("DD.MM.YYYY HH:mm");

        return date.format("DD/MM/YYYY HH:mm");
    },
    formatDateTimeShort: (date) => {
        return date.format("DD MMM, HH:mm");
    },
    formatTime: (date) => {
        return date.format("HH:mm");
    },
    parseDateWithOffset: (date, locale, offset) => {
        if (date == null)
            return "";
        if (locale == null)
            locale = "en";
        zmoment.locale(locale);
        date = zmoment(date);
        if (offset != null) {
            let serverOffset = Math.round(zmoment(new Date()).utcOffset(offset)._offset / 60);
            let clientOffset = Math.round(new Date().getTimezoneOffset() * -1 / 60);
            let hoursToAdd = clientOffset - serverOffset;
            if (hoursToAdd !== 0)
                date.add(hoursToAdd, "hours");
        }
        return date;
    },
    toggleLoading: (elem, loading) => {
        elem = $(elem);
        if (elem.length) {
            if (loading) {
                elem.prop("disabled", true);
                elem.find(".zicon").addClass("d-none");
                elem.find(".zloading").removeClass("d-none");
            } else {
                elem.prop("disabled", false);
                elem.find(".zicon").removeClass("d-none");
                elem.find(".zloading").addClass("d-none");

            }
        }
    },
    toggleDisable: (elem, disable) => {
        elem = $(elem);
        if (elem.length) {
            elem.prop("disabled", disable);
        }
    },
    hide: (elem) => {
        elem = $(elem);
        if (elem.length) {
            elem.addClass("d-none");
        }
    },
    show: (elem) => {
        elem = $(elem);
        if (elem.length) {
            elem.removeClass("d-none");
        }
    },
    check: (elem) => {
        elem = $(elem);
        if (elem.length) {
            elem.prop("checked", true);
        }
    },
    uncheck: (elem) => {
        elem = $(elem);
        if (elem.length) {
            elem.prop("checked", false);
        }
    },
    toggleClasses: (elem, remove, add) => {
        elem = $(elem);
        if (elem.length) {
            if (remove) {
                elem.removeClass(remove);
            }
            if (add) {
                elem.addClass(add);
            }
        }
    },
    fetchServerData: (url, successFunc, errorFunc) => {
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            async: true,
            success: function (data) {
                successFunc(data);
            },
            error: function (xhr, textStatus, errorThrown) {
                errorFunc(xhr, textStatus, errorThrown);
            }
        });
    },
    formatUUID: (uuid) => {
        let parts = uuid.split("-");
        return parts[parts.length - 1];
    },
    initTooltips: () => {
        $('*[data-mdb-toggle="tooltip"]').each((i, elem) => {
            new mdb.Tooltip(elem);
        });
    },
    toastFromXHRError: (xhr) => {
        if (typeof xhr === 'object' && 'responseJSON' in xhr && typeof xhr.responseJSON === 'object' && "error" in xhr.responseJSON) {
            ZToasts.error(xhr.responseJSON.error);
        }
    },
    postServerData: (url, data, successFunc, errorFunc) => {
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(data),
            async: true,
            success: function (data) {
                successFunc(data);
            },
            error: function (xhr, textStatus, errorThrown) {
                errorFunc(xhr, textStatus, errorThrown);
            }
        });
    },
    emptyFunction: () => {
    },
    compressImage: (file, width, height, quality, min, callback) => {
        if (min === 1) {
            new Compressor(file, {
                minWidth: width,
                minHeight: height,
                quality: quality,
                success(compressedFile) {
                    callback(compressedFile);
                },
                error(err) {
                    callback(file);
                }
            });
        } else {
            new Compressor(file, {
                maxWidth: width,
                maxHeight: height,
                quality: quality,
                success(compressedFile) {
                    callback(compressedFile);
                },
                error(err) {
                    callback(file);
                }
            });
        }
    },
    inArray: (array, elem) => {
        if (!Array.isArray(array))
            return false;
        if (!elem)
            return false;

        return array.includes(elem);
    },
    copyToClipboard: (text) => {
        navigator.clipboard.writeText(text);
    },

};
global.ZFUNC = ZFUNC;

const ZDatePicker = {
    options: {
        element: null,
        allowRepick: true,
        autoRefresh: true,
        format: "DD/MM/YYYY",
        lang: "en",
        singleMode: false
    },
    pickers: {},
    init: (locale) => {
        if (!locale)
            locale = "en";
        else if (locale.length > 2) {
            locale = locale.substring(0, 2);
        }
        ZDatePicker.options.lang = locale;
    },
    initElement: (element) => {
        ZDatePicker.options.element = element[0];
        ZDatePicker.options.maxDate = new Date();
        ZDatePicker.options.setup = (picker) => {
            picker.on("clear:selection", () => {
                ZFUNC.hide($(picker.options.element).parent().find(".zclear"));
                let e = $.Event("keyup");
                e.keyCode = 13;
                $(picker.options.element).trigger(e);
            });
            picker.on("selected", () => {
                ZFUNC.show($(picker.options.element).parent().find(".zclear"));
                let e = $.Event("keyup");
                e.keyCode = 13;
                $(picker.options.element).trigger(e);
            });
        }
        let id = uuid();
        ZDatePicker.pickers[id] = new Litepicker(ZDatePicker.options);
        $(element).parent().find(".zclear").data("picker", id).click((e) => {
            ZDatePicker.pickers[$(e.currentTarget).data("picker")].clearSelection();
        })

    }
}
global.ZDatePicker = ZDatePicker;

const ZDataTableWrapper = {
    loading: '.zdash-loading',
    rows: 5,
    order: [[0, 'asc']],
    init: function (id, url, columns, rows, order, searchCol) {
        ZDataTableWrapper.initWithRowCallback(id, url, columns, null, rows, order, searchCol);
    },
    initWithRowCallback: function (id, url, columns, rowCallBack, rows, order, searchCol) {
        if (!rows)
            rows = ZDataTableWrapper.rows;
        if (!order)
            order = ZDataTableWrapper.order;

        let searchCols = [];
        for (let i = 0; i < columns.length; i++) {
            if (searchCol && searchCol.index === i) {
                searchCols.push({
                    search: searchCol.value
                })
            } else {
                searchCols.push(null);
            }
        }
        $(id).DataTable({
            "iDisplayLength": rows,
            "processing": true,
            "serverSide": true,
            "pagingType": 'simple',
            order: order,
            "ajax": {
                "url": url,
                "type": "POST",
                "dataType": "json",
                "contentType": "application/json",
                "data": function (d) {
                    return JSON.stringify(d);
                },
                error: function (xhr, error, code) {
                    $('.table-responsive').removeClass("d-none").addClass("text-center")
                        .html(
                            '<i class="fas fa-exclamation-triangle fa-lg"></i>'
                        );
                    $(ZDataTableWrapper.loading).addClass("d-none");
                }
            },
            "columns": columns,
            "searchCols": searchCols,
            "language": {
                emptyTable: '<div class="text-center"><i class="fas fa-empty-set fa-lg"></i></div>',
                zeroRecords: '<div class="text-center"><i class="fas fa-empty-set fa-lg"></i></div>',
                processing: '',
                loadingRecords: '',
                infoEmpty: '0 - 0',
                info: "_START_ - _END_ of _TOTAL_ ",
                paginate: {
                    next: '<i class="fa fa-chevron-right"></i>',
                    previous: '<i class="fa fa-chevron-left"></i>',
                },
            },
            "initComplete": function () {
                this.api()
                    .columns()
                    .every(function () {
                        let column = this;
                        let input = $(column.footer()).find("input");
                        input.unbind("keyup change").on('keyup change', function (e) {
                            if (column.search() !== $(this).val() && e.keyCode === 13) {
                                column.search($(this).val()).draw();
                            }
                        });
                        $('<div class="zclear pointer py-1 d-flex align-items-center ms-2 d-none"><i class="fa fa-times fa-sm"></i></div>').insertAfter(input);
                        input.addClass("flex-grow-1");
                        if (input.hasClass("range-filter")) {
                            ZDatePicker.initElement(input);
                        } else {
                            input.on("keyup change", (e) => {
                                if ($(e.currentTarget).val().length > 0) {
                                    ZFUNC.show($(e.currentTarget).parent().find(".zclear"));
                                } else {
                                    ZFUNC.hide($(e.currentTarget).parent().find(".zclear"));
                                }
                            })
                            input.parent().find(".zclear").click((e) => {
                                let e2 = $.Event("keyup");
                                e2.keyCode = 13;
                                $(e.currentTarget).parent().find("input").val("").trigger(e2);
                            })
                        }
                        input.parent().addClass("d-flex");


                        let select = $(column.footer()).find("select");
                        select.unbind("change").on('change', function (e) {
                            if (column.search() !== $(this).val()) {
                                column.search($(this).val()).draw();
                            }
                        });
                    });

                $('.ztable').removeClass("d-none");
                $(ZDataTableWrapper.loading).addClass("d-none");
                $(id + ' tfoot tr').appendTo(id + ' thead');
                $(id + '_wrapper .row').addClass("m-0");
                $(id + '_wrapper .dt-row div').first().addClass("overflow-auto scrollable");
                $(id + '_wrapper .dataTables_length').parent().parent().remove();
                $(id + '_wrapper .pagination').addClass("justify-content-end");
                $(id + '_wrapper').find('label').each(function () {
                    $(this).parent().append($(this).children());
                });
            },
            "drawCallback": function (settings) {
                let api = this.api();
                if (rowCallBack != null) {
                    api.rows().every(function (index, element) {
                        rowCallBack(index, this.data());
                    });
                }
            }
        });
    },
    draw: (elem) => {
        $(elem).DataTable().draw();
    },
    applyFilter: (elem) => {
        let e = $.Event("keyup");
        e.keyCode = 13;
        $(elem).trigger(e);
    }
};
global.ZDataTableWrapper = ZDataTableWrapper;

const ZModal = {
    failedCommands: {},
    init: (id, hideCallback, otherFunc) => {
        let modalElem = $('#' + id + '-m');

        modalElem.on('hide.bs.modal', (e) => {
            if (hideCallback) {
                hideCallback(e);
            }
        });
        if (otherFunc) {
            otherFunc();
        }

        if (ZModal.failedCommands[id] != null) {
            modalElem.modal('show');
        }
    },
    hideModal: (elem) => {
        elem = $(elem);
        if (elem.length) {
            mdb.Modal.getInstance(elem).hide();
            $('.modal-backdrop').remove();
            elem.removeClass("show");
            $('body').removeClass("modal-open").css({
                "overflow": "inherit",
                "padding-right": "inherit"
            });
        }
    },
    disposeModal: (elem) => {
        ZModal.hideModal(elem);
        elem = $(elem);
        if (elem.length) {
            mdb.Modal.getInstance(elem).dispose();
            $(elem).remove();
        }
    }
};
global.ZModal = ZModal;

const EventBus = {
    subscribers: {},
    subscribe: (eventName, subscriber) => {
        if (eventName in EventBus.subscribers) {
            EventBus.subscribers[eventName].push(subscriber);
        } else {
            EventBus.subscribers[eventName] = [subscriber];
        }
    },
    publish: (eventName, eventObject) => {
        if (eventName in EventBus.subscribers) {
            $.each(EventBus.subscribers[eventName], (i, subscriber) => {
                subscriber.handle(eventObject);
            });
        }
    },
    init: () => {
        EventBus.subscribers = {};
    }
}
global.EventBus = EventBus;

const ZNotifications = {
    socket: null,
    endpoint: null,
    connected: false,
    subscribers: [],
    init: (endpoint) => {
        if (endpoint)
            ZNotifications.endpoint = endpoint;
        ZNotifications.socket = Stomp.over(() => {
            return new SockJS(ZNotifications.endpoint);
        });
        ZNotifications.socket.debug = f => f;
        ZNotifications.socket.debug = f => f;
        ZNotifications.socket.onWebSocketClose = f => {
            ZNotifications.connected = false;
            setTimeout(ZNotifications.init, 10000);
        };
        ZNotifications.socket.connect({}, () => {
            ZNotifications.connected = true;
            for (const subscriber of ZNotifications.subscribers) {
                ZNotifications.socket.subscribe(subscriber.endpoint, function (response) {
                    subscriber.callback(response);
                });
            }
        }, function () {
            ZNotifications.connected = false;
            setTimeout(ZNotifications.init, 5000);
        });
    },
    subscribe: (endpoint, callback) => {
        ZNotifications.subscribers.push({
            endpoint: endpoint,
            callback: callback
        });
    }
}
global.ZNotifications = ZNotifications;

